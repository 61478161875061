export const generateCSRFToken = async (force_new) => {
    let csrfTokenStore = useCSRFTokenStore();
    let csrf = csrfTokenStore.getToken;
    if ((csrf == null) || (force_new)) {
        const url = getUrl('/generate_custom_csrf_token');
        let data = await logFetchAJAX(url, {'key': '__dk__'});
        if (typeof(window) != 'undefined') {
            window['__NUXT__']['data']['__dk__'] = data;
        }
        let csrf = data['csrf'];
        csrfTokenStore.setToken(csrf);
    } else {
        if (typeof(window) != 'undefined') {
            window['__NUXT__']['data']['__dk__']['csrf'] = csrf;
        }
    }
    return csrf;
}