export const useCSRFTokenStore = defineStore("csrfTokenStore", {
    state: () => ({
        token: null,
    }),
    getters: {
        getToken() {
            return this.token;
        },
    },
    actions: {
        setToken(info) {
            this.token = info
        },
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCSRFTokenStore, import.meta.hot));
}